import React, { useRef } from 'react'
import './settings.css'
import { ToggleSlider } from "react-toggle-slider"
import { FcMusic } from 'react-icons/fc'
import music from '../../assets/music.mp3'

const Settings = () => {
    const audio = useRef(null);
    const toggleAudio = state => {
        if (!state) {
            audio.current.pause()
        }
        else {
            audio.current.play()
        }
    }
    return (
        <nav className='settings'>
            <FcMusic className='settings-icon' />
            <ToggleSlider onToggle={state => toggleAudio(state)} />
            <audio ref={audio}>
                <source src={music} type='audio/mpeg'/>
            </audio>
        </nav>
    )
}

export default Settings