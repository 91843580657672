import React from "react";
import "./about.css";
import ME from "../../assets/1.jpg";
import { FaAward } from "react-icons/fa";
import { AiOutlineCalendar } from "react-icons/ai";
import { MdLocationPin } from "react-icons/md";

const About = ({ setActiveNav }) => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="about" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <AiOutlineCalendar className="about__icon" />
              <h5>Age</h5>
              <small>22 Years</small>
            </article>
            <article className="about__card">
              <MdLocationPin className="about__icon" />
              <h5>Location</h5>
              <small>Iran,Shiraz</small>
            </article>
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Experience</h5>
              <small>+4 Years</small>
            </article>
          </div>
          <p>
            Hi, I'm <strong>Nima Sharifinia</strong> from Iran,Shiraz. I'm a
            22-year-old Iranian Fullstack Developer who strives to improve every
            single day. If you want website or mobile app for your business, you
            can contact me and it would be my pleasure to meet your needs.
          </p>
          <a
            href="#contact"
            className="btn btn-primary"
            onClick={() => setActiveNav("#contact")}
          >
            Hire me
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
