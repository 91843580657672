import React, { useCallback, useState } from "react";
import ImageViewer from "react-simple-image-viewer";
import "./portfolio.css";
import cheeplay_1 from "../../assets/cheeplay/home.png";
import cheeplay_2 from "../../assets/cheeplay/home2.png";
import cheeplay_3 from "../../assets/cheeplay/login.png";
import cheeplay_4 from "../../assets/cheeplay/login2.png";
import cheeplay_5 from "../../assets/cheeplay/dashboard.png";
import cheeplay_6 from "../../assets/cheeplay/profile.png";
import cheeplay_7 from "../../assets/cheeplay/payment.png";
import km_1 from "../../assets/kala market/home1.png";
import km_2 from "../../assets/kala market/home2.png";
import km_3 from "../../assets/kala market/orders.png";
import km_4 from "../../assets/kala market/search.png";
import km_5 from "../../assets/kala market/detail1.png";
import km_6 from "../../assets/kala market/detail2.png";
import aidiware_1 from "../../assets/Aidiware/register.png";
import aidiware_2 from "../../assets/Aidiware/login.png";
import aidiware_3 from "../../assets/Aidiware/error.png";
import gameshop from "../../assets/cheeplay/shop.png";
import panto_1 from "../../assets/PANTOhealth-mobile/splash.png";
import panto_2 from "../../assets/PANTOhealth-mobile/home.png";
import panto_3 from "../../assets/PANTOhealth-mobile/menu.png";
import panto_4 from "../../assets/PANTOhealth-mobile/loggingdata-stopped.png";
import panto_5 from "../../assets/PANTOhealth-mobile/loggingdata-started.png";
import panto_6 from "../../assets/PANTOhealth-mobile/settings.png";
import step_1 from "../../assets/Step/1.jpg";
import step_2 from "../../assets/Step/2.jpg";
import step_3 from "../../assets/Step/3.jpg";
import step_4 from "../../assets/Step/4.jpg";
import step_5 from "../../assets/Step/5.jpg";
import step_6 from "../../assets/Step/6.jpg";
import step_7 from "../../assets/Step/7.jpg";
import step_8 from "../../assets/Step/8.jpg";
import step_9 from "../../assets/Step/9.jpg";
import step_10 from "../../assets/Step/10.jpg";

const Portfolio = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [images, setImages] = useState([]);

  const images_cheeplay = [
    cheeplay_1,
    cheeplay_2,
    cheeplay_3,
    cheeplay_4,
    cheeplay_5,
    cheeplay_6,
    cheeplay_7,
  ];

  const images_km = [km_1, km_2, km_3, km_4, km_5, km_6];

  const images_aidiware = [aidiware_1, aidiware_2, aidiware_3];

  const images_gameshop = [gameshop, gameshop];

  const images_panto = [panto_1, panto_2, panto_3, panto_4, panto_5, panto_6];

  const images_step = [
    step_1,
    step_2,
    step_3,
    step_4,
    step_5,
    step_6,
    step_7,
    step_8,
    step_9,
    step_10,
  ];

  const openImageViewer = useCallback((index, src) => {
    setImages(src);
    setCurrentImage(index);
    setIsViewerOpen(true);
    document.getElementsByClassName("nav")[0].style.display = "none";
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
    document.getElementsByClassName("nav")[0].style.display = "flex";
  };
  return (
    <section id="portfolio">
      <h5>My Recent Works</h5>
      <h2>Portfolio</h2>
      <div className="container portfolio__container">
        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={cheeplay_1} alt="CHEEPLAY" />
          </div>
          <div>
            {isViewerOpen && (
              <ImageViewer
                src={images_cheeplay}
                currentIndex={currentImage}
                disableScroll={false}
                closeOnClickOutside={true}
                onClose={closeImageViewer}
              />
            )}
          </div>
          <h3>CHEEPLAY</h3>
          <div className="portfolio__item-cta">
            {/* <a
              href="javascript:void(0)"
              target="_blank"
              className="btn"
              rel="noreferrer"
            >
              Github
            </a> */}
            <a
              href="javascript:void(0)"
              className="btn btn-primary"
              onClick={() => openImageViewer(1, images_cheeplay)}
            >
              View More
            </a>
          </div>
        </article>
        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={km_1} alt="KALA MAKRKET" />
          </div>
          <div>
            {isViewerOpen && (
              <ImageViewer
                src={images_km}
                currentIndex={currentImage}
                disableScroll={false}
                closeOnClickOutside={true}
                onClose={closeImageViewer}
              />
            )}
          </div>
          <h3>KALA MARKET</h3>
          <div className="portfolio__item-cta">
            {/* <a
              href="javascript:void(0)"
              target="_blank"
              className="btn"
              rel="noreferrer"
            >
              Github
            </a> */}
            <a
              href="javascript:void(0)"
              className="btn btn-primary"
              rel="noreferrer"
              onClick={() => openImageViewer(1, images_km)}
            >
              View More
            </a>
          </div>
        </article>
        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={aidiware_1} alt="AIDIWARE" />
          </div>
          <div>
            {isViewerOpen && (
              <ImageViewer
                src={images_aidiware}
                currentIndex={currentImage}
                disableScroll={false}
                closeOnClickOutside={true}
                onClose={closeImageViewer}
              />
            )}
          </div>
          <h3>AIDIWARE</h3>
          <div className="portfolio__item-cta">
            {/* <a
              href="javascript:void(0)"
              target="_blank"
              className="btn"
              rel="noreferrer"
            >
              Github
            </a> */}
            <a
              href="javascript:void(0)"
              className="btn btn-primary"
              rel="noreferrer"
              onClick={() => openImageViewer(1, images_aidiware)}
            >
              View More
            </a>
          </div>
        </article>
        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={gameshop} alt="GAMESHOP" />
          </div>
          <div>
            {isViewerOpen && (
              <ImageViewer
                src={images_gameshop}
                currentIndex={currentImage}
                disableScroll={false}
                closeOnClickOutside={true}
                onClose={closeImageViewer}
              />
            )}
          </div>
          <h3>GAMING PRODUCTS STORE</h3>
          <div className="portfolio__item-cta">
            {/* <a
              href="javascript:void(0)"
              target="_blank"
              className="btn"
              rel="noreferrer"
            >
              Github
            </a> */}
            <a
              href="javascript:void(0)"
              className="btn btn-primary"
              rel="noreferrer"
              onClick={() => openImageViewer(1, images_gameshop)}
            >
              View More
            </a>
          </div>
        </article>
        <article className="portfolio__item">
          <div className="portfolio__item-image portfolio__item-mobileimg">
            <img src={panto_1} alt="PANTOhealth" />
          </div>

          <h3>PANTOhealth</h3>
          <div className="portfolio__item-cta">
            {/* <a
              href="javascript:void(0)"
              target="_blank"
              className="btn"
              rel="noreferrer"
            >
              Github
            </a> */}
            <a
              href="javascript:void(0)"
              className="btn btn-primary"
              onClick={() => openImageViewer(1, images_panto)}
            >
              View More
            </a>
          </div>
        </article>
        <article className="portfolio__item">
          <div className="portfolio__item-image portfolio__item-mobileimg">
            <img src={step_1} alt="Step" />
          </div>

          <h3>Step</h3>
          <div className="portfolio__item-cta">
            {/* <a
              href="javascript:void(0)"
              target="_blank"
              className="btn"
              rel="noreferrer"
            >
              Github
            </a> */}
            <a
              href="javascript:void(0)"
              className="btn btn-primary"
              onClick={() => openImageViewer(1, images_step)}
            >
              View More
            </a>
          </div>
        </article>
      </div>
      <div>
        {isViewerOpen && (
          <ImageViewer
            src={images}
            currentIndex={currentImage}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
            backgroundStyle={{ zIndex: 10 }}
          />
        )}
      </div>
    </section>
  );
};

export default Portfolio;
