import React from "react";
import "./testimonials.css";
import IMG_CLIENT from "../../assets/client.png";

// import swiper core and required modules
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// import swiper styles
import "swiper/css";
import "swiper/css/pagination";

const Testimonials = () => {
  return (
    <section id="testimonials">
      <h5>Review From Clients</h5>
      <h2>Testimonials</h2>
      <Swiper
        className="container testimonials__container"
        modules={[Pagination]}
        slidesPerView={1}
        spaceBetween={40}
        pagination={{ clickable: true }}
      >
        <SwiperSlide className="testimonial">
          <div className="client__avatar">
            <img src={IMG_CLIENT} alt="Ernest Achiever" />
          </div>
          <h5 className="client__name">daniAz</h5>
          <small className="client__review">
            He is very patient and polite and always available and answer
            messages as soon as possible. He is flexible to solve problems and
            do not choose only one way and consider different situations.
            Thanks!
          </small>
        </SwiperSlide>
        <SwiperSlide className="testimonial">
          <div className="client__avatar">
            <img src={IMG_CLIENT} alt="Shayan Mehtari" />
          </div>
          <h5 className="client__name">Shayan Mehtari</h5>
          <small className="client__review">
            He is very responsible, accurate and knowledgeable. I thank him for
            his efforts.
          </small>
        </SwiperSlide>
        <SwiperSlide className="testimonial">
          <div className="client__avatar">
            <img src={IMG_CLIENT} alt="Mehdi Rafiee" />
          </div>
          <h5 className="client__name">Mehdi Rafiee</h5>
          <small className="client__review">
            Very patient and accessible, noble and mastery of the subject was
            also excellent. Overall, it was very satisfying. Thank you!
          </small>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default Testimonials;
