import React from "react";
import CTA from "./CTA";
import "./header.css";
import IMG from "../../assets/2.png";
import HeaderSocials from "./HeaderSocials";

const Header = ({ setActiveNav }) => {
  const onScroll = (props) => console.log(props);
  return (
    <header onScroll={onScroll}>
      <div className="container header__container">
        <h5>Hello I'm</h5>
        <h1 className="name-title">Nima Sharifinia</h1>
        <h5 className="text-light">Fullstack Developer</h5>
        <CTA setActiveNav={setActiveNav} />
        <HeaderSocials />
        <div className="me">
          <img src={IMG} alt="me" />
        </div>
        <a
          href="#footer"
          className="scroll__down"
          onClick={() => setActiveNav("#contact")}
        >
          <span></span>
        </a>
      </div>
    </header>
  );
};

export default Header;
